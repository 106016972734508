import 'corejs-typeahead/dist/typeahead.jquery';
import Bloodhound from 'corejs-typeahead/dist/bloodhound';

$(function() {
  if ($('.typeahead-customers').length < 1) return;

  const customersSource = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: '../customers.json?search=%QUERY',
      wildcard: '%QUERY'
    }
  });

  function applyTypeahead(elements) {
    elements.typeahead(null, {
      name: 'customers',
      limit: 10,
      display: 'description',
      source: customersSource
    });

    // Update the hidden ID field when a customer is selected.
    elements.on('typeahead:selected', function(e, item) {
      const elementTypeaheadID = $(this).closest('.typeahead-container').find('.typeahead-id');
      elementTypeaheadID.val(item.id);
    });

    // Select all text when focused.
    elements.on('focus', function() {
      $(this).trigger('select');
    });
  }

  // Make sure new cocoon fields are also initialised.
  $(document).on('cocoon:after-insert', '.cocoon-container:has(.typeahead-customers)', function(e, insertedItem) {
    applyTypeahead($(insertedItem).find('.typeahead-customers'));
  });

  // Initialise all fields on load.
  applyTypeahead($('.typeahead-customers'));
});

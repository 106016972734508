import {Calendar} from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import moment from 'moment';

$(function () {
  const body = $('body');
  if (body.data('controller') !== 'appointments') return;

  // Global to hold the raw result of the JSON request.
  let appointmentsJSON = {};

  // Global to hold the Calendar object.
  let calendar = null;

  ////////////////////////////////////////////////////////////////////////////////

  function formatDateTime(datetime, format) {
    const output = moment(datetime);
    return output.isValid() ? output.format(format) : '';
  }

  function formatDateTimeComputer(datetime) {
    return formatDateTime(datetime, 'YYYY-MM-DD HH:mm');
  }

  function formatDateTimeHuman(datetime) {
    return formatDateTime(datetime, 'MMMM Do YYYY, h:mm a');
  }

  ////////////////////////////////////////////////////////////////////////////////

  // Use the ID param to popup the modal for the correct appointment.
  function showModalFromIdParam() {
    const appointment = appointmentFromId(getId());
    if (appointment !== null) {
      showEventModal(appointment);
      $('.nav-tabs a[href="#edit"]').tab('show');
    }
  }

  // Find the appointment from the JSON using the ID.
  function appointmentFromId(id) {
    const matches = jQuery.grep(appointmentsJSON, function (obj) {
      return obj.id === id;
    });
    return (matches.length === 0) ? null : matches[0];
  }

  // If it is an 'update' action, the ID will be in the main URL.
  // If it is an 'index' action, the ID will be as a URL param.
  function getId() {
    const action = body.data('action');
    if (action === 'index') {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('id');
    } else {
      return $('#appointment-id').data('id');
    }
  }

  ////////////////////////////////////////////////////////////////////////////////

  // Populate the modal 'show' tab with appointment field values.
  function populateShow(appointment) {
    function fillTitle() {
      const element = modal.find('.modal-title');
      const value = appointment.title;
      element.text(value);
    }

    function fillFullDay() {
      const element = modal.find('.modal-full-day');
      const method = appointment.full_day ? 'removeClass' : 'addClass';
      element[method]('d-none');
    }

    function fillStart() {
      const element = body.find('.modal-start');
      const value = formatDateTimeHuman(appointment.start);
      element.text(value);
    }

    function fillEnd() {
      const element = body.find('.modal-end');
      const value = formatDateTimeHuman(appointment.end);
      element.text(value);
    }

    function fillNotes() {
      const element = body.find('.modal-notes');
      const value = appointment.notes;
      element.text(value || '');
    }

    function fillCustomer() {
      const elementUrl = body.find('.modal-customer-url');
      const name = appointment.customer?.name;
      const url = appointment.customer?.url;
      const aTag = `<a href='${url}' target='_blank'>${name}</a>`;
      elementUrl.html(aTag);
      const elementBase = body.find('.modal-customer');
      const method = name ? 'removeClass' : 'addClass';
      elementBase[method]('d-none');
    }

    // Need to account for one, many, or none.
    function fillAllocatedTo() {
      const element = body.find('.modal-allocated-to');
      const users = appointment.allocated_to;
      body.find('.modal-allocated-to-extra').remove();
      if (users === undefined || users.length === 0) {
        const html = '<td class="text-dark">Allocated&nbsp;to:</td><td>All Users</td>';
        element.html(html);
      } else {
        $.each(users, function (index, user) {
          const aTag = `<a href='${user.url}' target='_blank'>${user.name}</a>`;
          if (index === 0) {
            const html = `<td class="text-dark">Allocated&nbsp;to:</td><td>${aTag}</td>`;
            element.html(html);
          } else {
            const td1 = '<td class="text-dark"></td>'
            const td2 = `<td>${aTag}</td>`
            const html = `<tr class="modal-allocated-to-extra">${td1}${td2}</tr>`;
            element.after(html);
          }
        });
      }
    }

    const modal = $('#modal-appointment');
    const body = modal.find('.modal-body');

    fillTitle();
    fillFullDay();
    fillStart();
    fillEnd();
    fillCustomer();
    fillAllocatedTo();
    fillNotes();
  }

  // Populate the modal 'edit' tab with appointment field values.
  function populateEdit(appointment) {
    function fillForm() {
      const start = formatDateTimeComputer(appointment.start);
      const end = formatDateTimeComputer(appointment.end);
      const allocated_to = $.map(appointment.allocated_to, i => i.id);
      $('form.edit_appointment #appointment_title').val(appointment.title);
      $('form.edit_appointment #appointment_start').val(start);
      $('form.edit_appointment #appointment_end').val(end);
      $('form.edit_appointment #appointment_full_day').prop('checked', appointment.full_day);
      $('form.edit_appointment #appointment_allocated_to_ids').val(allocated_to).trigger('change');
      $('form.edit_appointment #appointment_customer_id').val(appointment.customer.id);
      $('form.edit_appointment #appointment_notes').val(appointment.notes);
      $('form.edit_appointment').attr('action', `/appointments/${appointment.id}`);
    }

    fillForm();
  }

  // Populate the modal 'delete' button with the appointment URL.
  function populateDelete(appointment) {
    $('a#delete_appointment').attr('href', `/appointments/${appointment.id}`);
  }

  // Popup the modal for the appointment.
  function showEventModal(appointment) {
    populateShow(appointment);
    populateEdit(appointment);
    populateDelete(appointment);
    $('#modal-appointment').modal();
  }

  ////////////////////////////////////////////////////////////////////////////////

  // Convert the fullcalendar 'Event' type to the 'Appointment' type.
  function eventToAppointment(event) {
    return {
      id: event.id,
      title: event.title,
      start: event.start,
      end: event.end,
      full_day: event.allDay,
      customer: event.extendedProps?.customer,
      allocated_to: event.extendedProps?.allocated_to,
      notes: event.extendedProps?.notes
    };
  }

  // Fires when an event is clicked.
  function eventClick(info) {
    const appointment = eventToAppointment(info.event);
    showEventModal(appointment);
  }

  // Fires when a calendar date is clicked.
  function dateClick(info) {
    const date = `${info.dateStr} 09:00`;
    const title = `New Appointment - ${info.dateStr}`;
    $('form.new_appointment #appointment_start').val(date);
    $('form.new_appointment #appointment_title').val(title);
    $('#collapse-add-new').collapse('show');
  }

  // Fires when an event is dragged to new date, or start/end times are adjusted.
  function eventTimeChange(info) {
    const appointment = eventToAppointment(info.event);
    const data = {
      full_day: appointment.full_day,
      start: appointment.start,
      end: appointment.end
    };
    $.ajax({
      url: `/appointments/${appointment.id}.json`,
      data: JSON.stringify(data),
      type: 'PATCH',
      contentType: 'application/json'
    });
  }

  ////////////////////////////////////////////////////////////////////////////////

  $.getJSON('/appointments/preferences.json', function (prefs) {
    // Load the appointments JSON and build the calendar.
    $.getJSON('/appointments.json', function (data) {
      appointmentsJSON = data;
      buildCalendar(prefs);
      showModalFromIdParam();
      $('#toggle-all').prop('checked', true);
      $('#toggle-all').trigger('change');
    });
  });

  function buildCalendar(prefs) {
    const calendarEl = document.getElementById('calendar');
    calendar = new Calendar(calendarEl, {
      plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin],
      themeSystem: 'bootstrap',
      initialView: prefs.default_view,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      },
      navLinks: true,
      editable: true,
      // businessHours: {
      //   daysOfWeek: [1, 2, 3, 4, 5, 6],
      // startTime: '09:00',
      // endTime: '17:00'
      // },
      events: filterAppointments(appointmentsJSON),
      eventResize: eventTimeChange,
      eventDrop: eventTimeChange,
      eventClick: eventClick,
      dateClick: dateClick,
      datesSet: () => {
        $.post("/appointments/preferences.json", {last_view: calendar.view.type}, null, "application/json")
      }
    });

    calendar.render();
  };

  ////////////////////////////////////////////////////////////////////////////////

  // Filter according to the 'appointment.allocated_to' users.
  function filterAppointments(data) {

    // Find the users who are toggled.
    const toggledBoxes = $('.toggle-user').filter(function (index) {
      return $(this).is(':checked');
    });
    const userIDs = $.map(toggledBoxes, user => $(user).data('user-id'));

    // Filter where the users are allocated (or if no users are allocated).
    const forUser = function (appointment) {
      if (appointment.allocated_to.length === 0) return true;
      const allocated_to = $.map(appointment.allocated_to, i => i.id);
      return allocated_to.filter(i => userIDs.includes(i)).length !== 0;
    }
    return data.filter(forUser);
  }

  ////////////////////////////////////////////////////////////////////////////////

  // Clear the existing events, and re-filter.
  function refreshCalendar() {
    const appointments = filterAppointments(appointmentsJSON);
    calendar.getEventSources()[0].remove();
    calendar.addEventSource(appointments);
    calendar.refetchEvents();
  }

  // Toggle appointments for individual users.
  function toggleUserAppointments() {
    $('#toggle-all').prop('checked', false);
    $('#toggle-none').prop('checked', false);
    refreshCalendar();
  }

  // Toggle appointments for all users.
  function toggleAll() {
    if ($(this).is(':checked')) {
      $.each($('.toggle-user'), function () {
        $(this).prop('checked', true);
      });
      $('#toggle-none').prop('checked', false);
      refreshCalendar();
    }
  }

  // Toggle appointments for no users.
  function toggleNone() {
    if ($(this).is(':checked')) {
      $.each($('.toggle-user'), function () {
        $(this).prop('checked', false);
      });
      $('#toggle-all').prop('checked', false);
      refreshCalendar();
    }
  }

  $('.toggle-user').on('change', toggleUserAppointments);
  $('#toggle-none').on('change', toggleNone);
  $('#toggle-all').on('change', toggleAll);

  ////////////////////////////////////////////////////////////////////////////////

  // If a form fails validation, then only show the red field warnings on page load.
  // Reset/hide the warnings when the modal is hidden.
  $('#modal-appointment').on('hidden.bs.modal', function () {
    $('.invalid-feedback').addClass('d-none');
    $('form.edit_appointment #appointment_start').removeClass('is-invalid');
  });

  // Select all text when focused.
  $('form.new_appointment #appointment_title').on('focus', function () {
    $(this).select();
  });
});

$(document).ready(function() {

    function ToggleIf() {
        var $this = $(this);

        var showIfValue = $this.data('toggle-show-target-if');
        var currentValue = $this.val();
        var showElement = $($this.data('toggle-show-target'));

        // Only evaluate radio buttons & check boxes if they have been checked,
        // otherwise they just negate each other.
        if (($this.attr('type') == 'radio' || $this.attr('type') == 'checkbox') && $this.prop('checked') != true)
            return;

        if (String(showIfValue) == String(currentValue))
            showElement.show();
        else
            showElement.hide();

        if ($this.data('toggle-hide-target') == undefined)
            return;

        var hideElement = $($this.data('toggle-hide-target'));
        var hideIfValue = $this.data('toggle-hide-target-if');

        if (String(hideIfValue) == String(currentValue))
            hideElement.hide();
        else
            hideElement.show();
    }

    $('[data-toggle-show-target-if]').change(ToggleIf);
    $('[data-toggle-show-target-if]').each(ToggleIf);

    function ToggleRadioIf() {
        const $this = $(this);

        if (($this.attr('type') == 'radio' || $this.attr('type') == 'checkbox') && $this.prop('checked') != true)
            return;

        const toggleClass = $this.data('toggle-class');
        const toggleValue = $this.val();

        // Hide all the target elements
        $(`.${toggleClass}`).each( function(_index, element) {
            $(element).addClass('collapse');
        });

        // Display only selected target
        $(`.${toggleClass}[data-value='${toggleValue}']`).removeClass('collapse')
    }

    $('input.collapse-radio').change(ToggleRadioIf);
    $('input.collapse-radio').each(ToggleRadioIf);

    function ToggleSelectIf() {
        const $this = $(this);

        const toggleClass = $this.data('toggle-class');
        const toggleValue = $this.val();

        // Hide all the target elements
        $(`.${toggleClass}`).each( function(_index, element) {
            $(element).addClass('collapse');
        });

        // Display only selected target
        $(`.${toggleClass}[data-value]`).each(function(_index, element) {
            console.log( $(element));
            const elementValues = $(element).data("value").split(",").map(x => x.trim());
            if (elementValues.includes(toggleValue)) {
                $(element).removeClass('collapse');
            }
        });
        $(`.${toggleClass}[data-value='${toggleValue}']`).removeClass('collapse')
    }

    $('select.collapse-select').change(ToggleSelectIf);
    $('select.collapse-select').each(ToggleSelectIf);
});
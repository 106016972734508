import 'corejs-typeahead/dist/typeahead.jquery';
import Bloodhound from 'corejs-typeahead/dist/bloodhound';
import {currencyToNumber, numberToCurrency} from 'misc/currency';

// Update the currency amounts of the entire 'order'.
export function updateOrderPrices() {
  const elementAdjustedPrice = $('.order-adjusted-price');
  const elementDiscount = $('.order-discount');
  const elementTotalPrice = $('.order-total-price');
  const elementVat = $('.order-vat');

  // Loop through all cards on the page and sum all the values.
  let priceCounter = 0;
  $('.card.order-item').each(function() {
    priceCounter += currencyToNumber($(this).find('.field-price').text());
  });
  $('.order-adjusted-price').text(numberToCurrency(priceCounter));

  // Add the discount from the input field.
  const inputDiscount = $('input[data-autosubmit="true"][name="order[discount]"]');
  const orderDiscount = currencyToNumber(inputDiscount.val());
  $('.order-discount').text(numberToCurrency(orderDiscount));

  // Perform the price calculations.
  const vat = Number($('body').data('vat'));
  const totalPrice = priceCounter + orderDiscount;
  const ofWhichVat = totalPrice - (totalPrice / (1 + vat));
  $('.order-total-price').text(numberToCurrency(totalPrice));
  $('.order-vat').text(numberToCurrency(ofWhichVat));
}

// Update the currency amounts of the 'order_item' card totals.
export function updateOrderItemPrices(card) {
  // Find the card totals.
  let totalCost = 0;
  card.find('.nested-fields:visible .component-cost').each(function() {
    totalCost += currencyToNumber($(this).html());
  });
  let totalPrice = 0;
  card.find('.nested-fields:visible .component-price').each(function() {
    totalPrice += currencyToNumber($(this).html());
  });

  // Update the card totals.
  card.find('.field-price').data('price', totalPrice);
  card.find('.field-cost').data('cost', totalCost);
  card.find('.field-cost').text(numberToCurrency(totalCost));

  // For price, also add the adjustment.
  const adjustment = currencyToNumber(card.find('input[name="order_item[adjustment]"]').val());
  card.find('.field-price').text(numberToCurrency(totalPrice + adjustment));

  updateOrderPrices();
}

////////////////////////////////////////////////////////////////////////////////

$(function() {
  if ($('.typeahead-products').length < 1) return;

  const productsSource = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: '../../../products.json?search=%QUERY',
      wildcard: '%QUERY'
    }
  });

  function applyTypeahead(elements) {
    elements.typeahead(null, {
      name: 'products',
      limit: 20,
      display: 'description',
      source: productsSource
    });

    // Update the hidden ID field when a customer is selected.
    elements.on('typeahead:selected', function(e, item) {
      const self = $(this);
      const row = self.closest('tr');
      const elementTypeaheadID = self.closest('.typeahead-container').find('.typeahead-id');
      elementTypeaheadID.val(item.id);

      // Also update the data attributes.
      row.data('retail-unit-price', item.retail_unit_price);
      row.data('unit-cost', item.unit_cost);
      row.data('bulk-cost', item.bulk_cost);

      // And the product link.
      const link = row.find('.product-url');
      link.attr('href', item.url);
      link.attr('title', item.description);
      link.removeClass('d-none');

      updateRowPrices(row);
    });

    // Select all text when focused.
    elements.on('focus', function() {
      $(this).trigger('select');
    });
  }

  // Initialise all fields on load.
  applyTypeahead($('.typeahead-products'));

  ////////////////////////////////////////////////////////////////////////////////

  // Update the display of prices, based on the row's data attributes.
  function updateRowPrices(row) {
    const retailUnitPrice = row.data('retail-unit-price');
    const unitCost = row.data('unit-cost');
    const bulkCost = row.data('bulk-cost');
    const isBulk = row.find('.order_item_components_bulk_purchase').find('input').is(':checked');
    const quantity = row.find('.order_item_components_quantity').find('input').val();

    // Calculate the row's cost and price values (prices are in pence).
    const cost = quantity * ((isBulk ? bulkCost : unitCost) / 100);
    const price = quantity * (retailUnitPrice / 100);
    row.find('.component-cost').html(numberToCurrency(cost));
    row.find('.component-price').html(numberToCurrency(price));

    const card = row.closest('.card');
    updateOrderItemPrices(card);
  }

  function eventUpdateRowPrices() {
    updateRowPrices($(this).closest('tr'));
  }

  $('.order_item_components_quantity input').on('change', eventUpdateRowPrices);
  $('.order_item_components_bulk_purchase input').on('change', eventUpdateRowPrices);

  ////////////////////////////////////////////////////////////////////////////////

  // Make sure new cocoon fields are also initialised.
  $(document).on('cocoon:after-insert', '.cocoon-container:has(.typeahead-products)', function(e, insertedItem) {
    applyTypeahead($(insertedItem).find('.typeahead-products'));
    $(insertedItem).find('.order_item_components_quantity input').on('change', eventUpdateRowPrices);
    $(insertedItem).find('.order_item_components_bulk_purchase input').on('change', eventUpdateRowPrices);
  });

  // Update the price when a cocoon item is deleted.
  $(document).on('cocoon:after-remove', '.cocoon-container:has(.typeahead-products)', function(e) {
    const card = $(e.target).closest('.card');
    updateOrderItemPrices(card);
  });

  ////////////////////////////////////////////////////////////////////////////////

  // Visual indicator for if the cocoon fields have unsaved changes.
  function dirtyForm() {
    const submit = $(this).closest('.card').find('input[type="submit"]');
    submit.removeClass('disabled');
  }
  $(document).on('cocoon:after-remove', '.cocoon-container:has(.typeahead-products)', dirtyForm);
  $(document).on('cocoon:after-insert', '.cocoon-container:has(.typeahead-products)', dirtyForm);
  $('form.edit_order_item').on('change', dirtyForm);

  // 'Clean' the submit button after the form is posted.
  $('form.edit_order_item input[type="submit"]').on('click', function() {
    $(this).addClass('disabled');
  });
});

import {currencyToNumber, numberToCurrency} from 'misc/currency';
import {updateOrderItemPrices} from 'customers/order_item_cocoon';

$(function() {
  if ($('input[data-autosubmit="true"]').length < 1) return;

  function submitValue(record, field, element, callback) {
    const url = $(element).closest('form').attr('action');
    const data = {};
    data[record] = {};
    data[record][field] = $(element).val();

    // The spinner is shown, and then hidden again in the callback.
    const iconSpinner = $(element).siblings('small').find('.icon-spinner');
    iconSpinner.removeClass('d-none');

    $.ajax({
      url: url,
      type: 'PATCH',
      dataType: 'json',
      data: data,
      success: function(result) {
        callback(result);
        setTimeout(function() {
          iconSpinner.addClass('d-none');
        }, Math.random() * 600 + 200);
      }
    });
  }

  const inputName = $('input[data-autosubmit="true"][name="order_item[name]"]');
  inputName.on('blur', function(e) {
    submitValue('order_item', 'name', $(e.target), function() { });
  });

  const inputAdjustment = $('input[data-autosubmit="true"][name="order_item[adjustment]"]');
  inputAdjustment.on('blur', function(e) {
    submitValue('order_item', 'adjustment', $(e.target), function(result) {
      const card = $(e.target).closest('.card');
      updateOrderItemPrices(card);
    });
  });

  ////////////////////////////////////////////////////////////////////////////////

  function submitBoolean(url, record, field, value, callback) {
    const data = {};
    data[record] = {};
    data[record][field] = value;

    $.ajax({
      url: url,
      type: 'PATCH',
      dataType: 'json',
      data: data,
      success: callback
    });
  }

  const booleanFields = ['ready_to_fit', 'completed'];
  $.each(booleanFields, function(index, field) {
    $(`.order-item-field-${field}`).on('click', function(e) {
      const element = $(e.target).closest(`.order-item-field-${field}`);
      const form = $(e.target).closest('.card').find('form');
      const oldBoolean = form.data(field);
      const newBoolean = !oldBoolean;
      const url = form.attr('action');

      submitBoolean(url, 'order_item', field, newBoolean, function(result) {
        element.find(`.icon-${oldBoolean}`).addClass('d-none');
        element.find(`.icon-${newBoolean}`).removeClass('d-none');
        $(e.target).closest('.card').find('form').data(field, newBoolean);
      });
    });
  });
});

import $ from 'jquery';

// If the 'search' param is present, scroll to the card containing the table.
$(function() {
  const params = new URLSearchParams(window.location.search);
  const hasSearch = params.has('search');
  if (hasSearch) {
    document.querySelector('#table-card').scrollIntoView({
      behavior: 'smooth'
    });
  }
});

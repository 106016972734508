import 'jquery-datetimepicker';

$(function() {
  function applyDateTimePickers() {
    $('.date-time-picker').datetimepicker({
      datepicker: true,
      timepicker: true,
      format: 'd M Y H:i',
      step: 15
    });
  }
  function applyDatePickers() {
    $('.date-picker').datetimepicker({
      datepicker: true,
      timepicker: false,
      format: 'd M Y'
    });
  }
  function applyTimePickers() {
    $('.time-picker').datetimepicker({
      datepicker: false,
      timepicker: true,
      format: 'H:i',
      step: 15
    });
  }

  $(document).on('cocoon:after-insert', '.cocoon-container:has(.date-time-picker)', applyDateTimePickers);
  $(document).on('cocoon:after-insert', '.cocoon-container:has(.date-picker)', applyDatePickers);
  $(document).on('cocoon:after-insert', '.cocoon-container:has(.time-picker)', applyTimePickers);

  applyDateTimePickers();
  applyDatePickers();
  applyTimePickers();
});

import $ from 'jquery';

$(function() {
  if ($('body').data('controller') != 'orders' && $('body').data('action') != 'show') return;

  // Currency fields have £ added onBlur, remove it for maths
  const parseFloatWithoutCurrency = (value) => parseFloat(value.replace('£', ''));

  const displayUnsavedBalance = () => {
    const totalPrice = parseFloat($('[data-total-price]').data('total-price'));
    let totalPaymentsAmount = 0.0;

    // Add up payments
    $('.cocoon-container.payments input[type="currency"]').each(function() {
      if ($(this).is(":visible")) {
        totalPaymentsAmount += parseFloatWithoutCurrency($(this).val());
      }
    });

    const originalBalanceDue = parseFloatWithoutCurrency($('[data-balance-due-original]').data('balance-due-original')).toFixed(2);
    const newBalanceDue = (totalPrice - totalPaymentsAmount).toFixed(2);

    // Warn user if balance differs from what is saved
    if (originalBalanceDue === newBalanceDue) {
      $('#balance-due-message').removeClass('text-warning');
      $('#balance-due-warning').addClass('d-none');
    } else {
      $('#balance-due-message').addClass('text-warning');
      $('#balance-due-warning').removeClass('d-none');
    }

    // Display new balance
    $('span.balance-due').html(`£${newBalanceDue}`);
  }

  // On payment remove
  $('.cocoon-container.payments').on('cocoon:after-remove', displayUnsavedBalance);
  // On payment update
  $('body').on('change', '.cocoon-container.payments input[type="currency"]', displayUnsavedBalance);
});

import Bloodhound from "corejs-typeahead/dist/bloodhound";

$(function() {
    if ($('.typeahead-blind-spec-rail-colour').length < 1) return;

    const blindSpecRailsColourSource = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: './rail_colour',
            wildcard: '%QUERY'
        }
    });

    function applyTypeahead(elements) {
        elements.typeahead(null, {
            name: 'blind_specification_rails_colour',
            limit: 20,
            source: blindSpecRailsColourSource
        });
    }

    applyTypeahead($('.typeahead-blind-spec-rail-colour'));
});
import moment from 'moment';
import 'daterangepicker';

$(function() {
    if ($('#report-date-range').length < 1) return;

    const queryString = window.location.search;

    function getStart() {
        const date = moment($('#report-date-range').data('start'), 'YYYY-MM-DD');
        return date.isValid() ? date : moment().subtract(29, 'days');
    }

    function getEnd() {
        const date = moment($('#report-date-range').data('end'), 'YYYY-MM-DD');
        return date.isValid() ? date : moment();
    }

    function updateLabel(start, end) {
        const startText = start.format('D MMMM YYYY');
        const endText = end.format('D MMMM YYYY');
        const buttonText = (startText === endText) ? startText : startText + ' &mdash; ' + endText;
        $('#report-date-range span').html(buttonText);
    }

    function submitDatesCallback(start, end) {
        document.body.style.cursor = 'wait';
        updateLabel(start, end);
        const startText = start.format('YYYY-MM-DD');
        const endText = end.format('YYYY-MM-DD');
        window.location.href = window.location.pathname + '?' + $.param(
            {start: startText, end: endText}
        );
    }

    $('#report-date-range').daterangepicker(
        {
            startDate: getStart(),
            endDate: getEnd(),
            opens: 'left',
            alwaysShowCalendars: true,
            ranges: {
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Current Year': [moment().startOf('year'), moment()],
                'Last Year': [moment().startOf('year').subtract(1, 'year'), moment().startOf('year').subtract(1, 'day')],
                'Last 12 Months': [moment().subtract(12, 'months'), moment()],
                '<strong>Today</strong>': [moment(), moment()],
                'Next 7 Days': [moment(), moment().add(6, 'days'), ],
                'Next 30 Days': [moment(), moment().add(29, 'days')],
            },
        },
        submitDatesCallback
    );
});

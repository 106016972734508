require('@rails/ujs').start();
require('@rails/activestorage').start();
require('channels');
require('data-confirm-modal');
require('@nathanvda/cocoon');

const images = require.context('../images', true);

import $ from 'jquery';
import toastr from 'toastr/toastr';
import 'bootstrap/dist/js/bootstrap';

import '../sb-admin-pro/js/scripts';
import '../sb-admin-pro/css/styles';
import '../appointments/calendar';
import '../customers/blind_spec';
import '../customers/order_item_cocoon';
import '../customers/order_items';
import '../customers/orders';
import '../customers/typeahead';
import '../enquiries/tabs';
import '../enquiries/toggle';
import '../orders/payments';
import '../misc/accordion';
import '../misc/blind_spec';
import '../misc/chosen.jquery.min';
import '../misc/components';
import '../misc/currency';
import '../misc/daterangepicker';
import '../misc/datetimepicker';
import '../misc/select2';
import '../misc/toggleif';
import '../staff/toggle';
import '../tables/scroll';

window.toastr = toastr;
window.$ = $

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

$(function () {
  $(".chosen-select").chosen();
});

$(function() {
  $('[data-print-page]').click(function() {
    window.print();
    return false;
  });
});

import $ from 'jquery';

$(function() {
  if ($('body').data('controller') != 'enquiries') return;

  $('.nav-tabs').find('a').on('shown.bs.tab', function(e) {
    const tabName = $(e.target).attr('href').replace('#', '');
    $('.tab-name').val(tabName);
  });
});

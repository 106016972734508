import {updateOrderPrices} from 'customers/order_item_cocoon';

$(function() {
  if ($('input[data-autosubmit="true"]').length < 1) return;

  function submitValue(record, field, element, callback) {
    const url = $(element).closest('form').attr('action');
    const data = {};
    data[record] = {};
    data[record][field] = $(element).val();

    $.ajax({
      url: url,
      type: 'PATCH',
      dataType: 'json',
      data: data,
      success: callback
    });
  }

  const inputNotes = $('textarea[data-autosubmit="true"][name="order[notes]"]');
  inputNotes.on('blur', function(e) {
    submitValue('order', 'notes', $(e.target), function() { });
  });

  const inputDiscount = $('input[data-autosubmit="true"][name="order[discount]"]');
  inputDiscount.on('blur', function(e) {
    submitValue('order', 'discount', $(e.target), function() { updateOrderPrices() });
  });

  ////////////////////////////////////////////////////////////////////////////////

  function submitBoolean(url, record, field, value, callback) {
    const data = {};
    data[record] = {};
    data[record][field] = value;

    $.ajax({
      url: url,
      type: 'PATCH',
      dataType: 'json',
      data: data,
      success: callback
    });
  }

  const booleanFields = ['follow_up_req', 'completed'];
  $.each(booleanFields, function(index, field) {
    $(`.order-field-${field}`).on('click', function(e) {
      const element = $(e.target).closest(`.order-field-${field}`);
      const form = $(e.target).closest('.card');
      const oldBoolean = form.data(field);
      const newBoolean = !oldBoolean;
      const url = form.attr('action');

      submitBoolean(url, 'order', field, newBoolean, function(result) {
        console.log(result);
        element.find(`.icon-${oldBoolean}`).addClass('d-none');
        element.find(`.icon-${newBoolean}`).removeClass('d-none');
        $(e.target).closest('.card').data(field, newBoolean);
      });
    });
  });

  ////////////////////////////////////////////////////////////////////////////////

  const selectEnquiry = $('select[data-autosubmit="true"][name="order[enquiry_id]"]');
  selectEnquiry.on('change', function(e) {
    const self = $(this);
    const enquiryDate = self.find('option:selected').text().split(' — ')[0];
    const enquiryId = self.val();
    const linkElement = $('.order-enquiry-link');

    if (enquiryId) {
      const url = `${window.location.href.split('orders')[0]}enquiries/${enquiryId}`;
      linkElement.html(`<a href="${url}" target=_blank class="order-link">${enquiryDate}</a>`);
    } else {
      linkElement.html('[none]');
    }

    submitValue('order', 'enquiry_id', $(e.target), function() { });
  });

  ////////////////////////////////////////////////////////////////////////////////

  var showCosts = false;
  function toggleCosts() {
    showCosts = !showCosts;
    displayCosts();
  }

  function displayCosts() {
    const method = showCosts ? 'removeClass' : 'addClass';
    $('.cost-element')[method]('d-none');

    const text = showCosts ? 'Hide Costs' : 'Show Costs';
    $('button.toggle-costs').find('span.button-text').text(text);
  }

  // Toggle when modal is confirmed.
  // (no need to display the modal when toggling off)
  $('button.toggle-costs').on('click', function() {
    if (showCosts) {
      toggleCosts();
    } else {
      $('#toggle-costs-modal').modal('show');
      setTimeout(function() {
        $('#modal-btn-no').trigger('focus');
      }, 100);
    }
  });

  $('#modal-btn-yes').on('click', function(){
    $('#toggle-costs-modal').modal('hide');
    toggleCosts();
  });

  $('#modal-btn-no').on('click', function(){
    $('#toggle-costs-modal').modal('hide');
  });

  // Set new cocoon items to the correct display.
  $(document).on('cocoon:after-insert', '.cocoon-container:has(.cost-element)', displayCosts);
});

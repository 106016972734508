import $ from 'jquery';

$(function() {
  const toggleElement = $('#show-closed-enquiries');

  if (toggleElement.length < 1) return;

  toggleElement.on('change', function(e) {
    $('form').submit();
  });
});

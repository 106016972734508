$(document).ready(function() {
    ["select[name='quotation_item_blind_specification[perfect_fit_frame_colour]']", "select[name='quotation_item_blind_specification[rail_colour]']"].forEach(function(selector) {
        $(selector).change(function() {
            var optionSelected = $("option:selected", this);
            var extraCostWarning = optionSelected.data('extra-cost-warning');
            var extraCostWarningTarget = $(optionSelected).data('extra-cost-warning-target');
            if (extraCostWarning !== undefined) {
                $(extraCostWarningTarget).removeClass('collapse').addClass('show');
                $(extraCostWarningTarget).find('.warning-msg').text(extraCostWarning);
            } else {
                $(extraCostWarningTarget).removeClass('show').addClass('collapse');
                $(extraCostWarningTarget).find('.warning-msg').text('');
            }
        }).change();
    })

});

import $ from "jquery";

$(function () {
  const productSelect = $('select.component-product-id');
  productSelect.on('change',function () {
    const optionSelected = $("option:selected", this);
    const params = new URLSearchParams(location.search);
    params.set('product_id', optionSelected.attr('value'));
    window.location = `${location.pathname}?${params.toString()}`;
    const form = productSelect.closest("form");
    form.slideUp();
    form.parent().prepend("Loading, please wait...");
  });
});
